import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MainView from '../shared/views/Main.view';
import ErrorView from '../shared/views/Error.view';
import LoadingView from '../shared/views/Loading.view';
import SuccessView from '../shared/views/Success.view';
import { parse } from 'query-string';
import { personEventApi } from '../shared/services/Apis';
import { ClientPersonVerifyEmailAddressResponse } from 'digitale-doerfer-api/models';

enum ComponentState {
	INITIAL,
	QUERY_PARAMS_PARSED,
	LOADING,
	ERROR,
	SUCCESS,
}

type RequestData = {
	personId: string;
	token: string;
};

function EmailLandingPageContainer(): JSX.Element {
	const defaultSuccessMessage = 'Prima, deine E-Mail ist bestätigt.';
	const defaultErrorMessage = 'Ups, etwas ist schiefgelaufen';
	const [pageState, setPageState] = useState<ComponentState>(
		ComponentState.INITIAL
	);
	const [requestData, setRequestData] = useState<RequestData | null>(null);
	const [errorCode, setErrorCode] = useState<string>('');
	const [errorMessage, setErrorMessage] = useState<string>(defaultErrorMessage);
	const [successMessage, setSuccessMessage] = useState<string>(
		defaultSuccessMessage
	);

	const { search } = useLocation();

	if (pageState === ComponentState.INITIAL) {
		document.title = 'E-Mail-Bestätigung';
		const { person, token } = parse(search, { decode: true });
		if (typeof person !== 'string' || typeof token !== 'string') {
			setPageState(ComponentState.ERROR);
			setErrorCode('MISSING_OR_INVALID_QUERY_PARAM');
		} else {
			setPageState(ComponentState.QUERY_PARAMS_PARSED);
			setRequestData({
				personId: person,
				token,
			});
		}
	}

	async function verifyMail(requestData: RequestData): Promise<void> {
		try {
			const response: ClientPersonVerifyEmailAddressResponse = await personEventApi().onPersonVerifyEmailRequestUsingPOST(
				{
					mailToken: requestData.token,
					clientRequest: {
						personId: requestData.personId,
					},
				}
			);
			setPageState(ComponentState.SUCCESS);
			setSuccessMessage(response?.message ?? defaultSuccessMessage);
		} catch (error) {
			// if the backend returns a non-ok code, the complete API Response
			// object is thrown as an error
			if (error instanceof Response) {
				const json = await error.json();
				if (error.status === 400) {
					setErrorMessage(json.message ?? defaultErrorMessage);
				} else {
					setErrorCode(json.type);
				}
				setPageState(ComponentState.ERROR);
			} else {
				setPageState(ComponentState.ERROR);
			}
		}
	}

	useEffect(() => {
		if (
			pageState === ComponentState.QUERY_PARAMS_PARSED &&
			requestData !== null
		) {
			setPageState(ComponentState.LOADING);
			verifyMail(requestData);
		}
	}, [pageState, requestData]);

	return (
		<MainView>
			{pageState === ComponentState.LOADING && <LoadingView />}
			{pageState === ComponentState.SUCCESS && (
				<SuccessView
					message={successMessage}
					additionalMessage="Wir freuen uns auf deine Beiträge!"
				/>
			)}
			{pageState === ComponentState.ERROR && (
				<ErrorView message={errorMessage} backendErrorCode={errorCode} />
			)}
		</MainView>
	);
}

export default EmailLandingPageContainer;
