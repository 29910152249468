import React from 'react';
import logo from '../../assets/digitale-doerfer-logo@2x.png';

type Props = {
	children?: React.ReactNode;
};

function MainView({ children }: Props): JSX.Element {
	return (
		<>
			<div className="background"></div>

			<div className="fullscreen">
				<div className="signup center-outer">
					<div className="signup center">
						<form className="signup widget">
							<div className="widget-container">
								<div className="header">
									<img
										className="dd-logo"
										alt="Digitale Dörfer Logo"
										src={logo}
									/>
								</div>

								<div className="main">
									<>{children}</>

									<p
										className="support"
										style={{
											boxSizing: 'border-box',
											margin: 'auto auto 22px auto',
											fontSize: 12,
											color: '#333',
											lineHeight: 1.6,
											fontWeight: 500,
											textAlign: 'center',
										}}
									>
										Benötigst du Hilfe? Kontaktiere uns bitte unter{' '}
										<a href="mailto:support@digitale-doerfer.de">
											support@digitale-doerfer.de
										</a>
										.
									</p>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

export default MainView;
