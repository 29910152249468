import React from 'react';
import { ReactComponent as SuccessLogo } from '../../assets/success.svg';

type Props = {
	message: string;
	additionalMessage?: string;
};

function SuccessView({ message, additionalMessage }: Props): JSX.Element {
	return (
		<>
			<p>
				<SuccessLogo />
			</p>
			<p>
				{message}
				{additionalMessage && <br />}
				{additionalMessage}
			</p>
		</>
	);
}

export default SuccessView;
