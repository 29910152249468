import React from 'react';
import EmailLandingPageContainer from './landing-pages/EmailLandingPage.container';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import GroupJoinApproveDenyLandingPageContainer from './landing-pages/GroupJoinApproveDenyLandingPage.container';
import AccountConfirmationLandingPageContainer from './landing-pages/AccountConfirmationLandingPage.container';

function PlatformLandingPagesApp(): JSX.Element {
	return (
		<Router>
			<Switch>
				<Route path="/account-confirmation">
					<AccountConfirmationLandingPageContainer />
				</Route>
				<Route path="/email-confirmation">
					<EmailLandingPageContainer />
				</Route>
				<Route path="/group-mail">
					<GroupJoinApproveDenyLandingPageContainer />
				</Route>
				{/* Redirect to digitale-doerfer homepage when called without specific path */}
				<Route
					path="/"
					component={(): null => {
						window.location.href = 'https://www.digitale-doerfer.de';
						return null;
					}}
				/>
			</Switch>
		</Router>
	);
}

export default PlatformLandingPagesApp;
