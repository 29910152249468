import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MainView from '../shared/views/Main.view';
import ErrorView from '../shared/views/Error.view';
import LoadingView from '../shared/views/Loading.view';
import SuccessView from '../shared/views/Success.view';
import { parse } from 'query-string';
import { personEventApi } from '../shared/services/Apis';

enum ComponentState {
	INITIAL,
	QUERY_PARAMS_PARSED,
	LOADING,
	ERROR,
	SUCCESS,
}

type RequestData = {
	personId: string;
	token: string;
};

function AccountConfirmationLandingPageContainer(): JSX.Element {
	const [pageState, setPageState] = useState<ComponentState>(
		ComponentState.INITIAL
	);
	const [requestData, setRequestData] = useState<RequestData | null>(null);
	const [errorCode, setErrorCode] = useState<string>('');

	const { search } = useLocation();

	if (pageState === ComponentState.INITIAL) {
		document.title = 'Account-Bestätigung';
		const { person, token } = parse(search, { decode: true });
		if (typeof person !== 'string' || typeof token !== 'string') {
			setPageState(ComponentState.ERROR);
			setErrorCode('MISSING_OR_INVALID_QUERY_PARAM');
		} else {
			setPageState(ComponentState.QUERY_PARAMS_PARSED);
			setRequestData({
				personId: person,
				token,
			});
		}
	}

	async function verifyMail(requestData: RequestData): Promise<void> {
		try {
			await personEventApi().resetLastLoggedInRequestMailUsingPOST({
				mailToken: requestData.token,
				clientRequest: {
					personId: requestData.personId,
				},
			});
			setPageState(ComponentState.SUCCESS);
		} catch (error) {
			// if the backend returns a non-ok code, the complete API Response
			// object is thrown as an error
			if (error instanceof Response) {
				const json = await error.json();
				setErrorCode(json.type);
				setPageState(ComponentState.ERROR);
			} else {
				setPageState(ComponentState.ERROR);
			}
		}
	}

	useEffect(() => {
		if (
			pageState === ComponentState.QUERY_PARAMS_PARSED &&
			requestData !== null
		) {
			setPageState(ComponentState.LOADING);
			verifyMail(requestData);
		}
	}, [pageState, requestData]);

	return (
		<MainView>
			{pageState === ComponentState.LOADING && <LoadingView />}
			{pageState === ComponentState.SUCCESS && (
				<SuccessView
					message="Prima, dein Konto bleibt erhalten."
					additionalMessage="Wir freuen uns, dich wiederzusehen!"
				/>
			)}
			{pageState === ComponentState.ERROR && (
				<ErrorView
					message="Ups, etwas ist schiefgelaufen"
					backendErrorCode={errorCode}
				/>
			)}
		</MainView>
	);
}

export default AccountConfirmationLandingPageContainer;
