import React from 'react';
import { ReactComponent as WarningLogo } from '../../assets/warning.svg';

type Props = {
	message: string;
};

function WarningView({ message }: Props): JSX.Element {
	return (
		<>
			<p>
				<WarningLogo />
			</p>
			<p>{message}</p>
		</>
	);
}

export default WarningView;
