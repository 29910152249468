import React from 'react';

function LoadingView(): JSX.Element {
	return (
		<>
			<p
				style={{
					fontSize: '100px',
					color: '#a0a0a0',
					marginTop: 0,
					marginBottom: 0,
				}}
			>
				?
			</p>
			<p>Einen Moment bitte...</p>
		</>
	);
}

export default LoadingView;
