import React from 'react';
import { ReactComponent as ErrorLogo } from '../../assets/error.svg';

type Props = {
	message: string;
	backendErrorCode?: string;
};

function ErrorView({ message, backendErrorCode }: Props): JSX.Element {
	return (
		<>
			<p>
				<ErrorLogo />
			</p>
			<p>
				{message}
				{backendErrorCode && ` (${backendErrorCode})`}
			</p>
		</>
	);
}

export default ErrorView;
