import { ParticipantsPersonEventsApi } from 'digitale-doerfer-api/apis/ParticipantsPersonEventsApi';
import { GrapevineGroupEventsApi } from 'digitale-doerfer-api/apis/GrapevineGroupEventsApi';
import { Configuration } from 'digitale-doerfer-api/runtime';
import { BACKEND_URL } from './Configuration';

function lazyInit<T>(init: () => T): () => T {
	let value: T;

	return (): T => {
		if (!value) {
			value = init();
		}
		return value;
	};
}

const configuration = new Configuration({
	basePath: BACKEND_URL,
});

export const personEventApi = lazyInit(
	() => new ParticipantsPersonEventsApi(configuration)
);

export const groupEventApi = lazyInit(
	() => new GrapevineGroupEventsApi(configuration)
);
