import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MainView from '../shared/views/Main.view';
import ErrorView from '../shared/views/Error.view';
import LoadingView from '../shared/views/Loading.view';
import SuccessView from '../shared/views/Success.view';
import { parse } from 'query-string';
import { groupEventApi } from '../shared/services/Apis';
import {
	ClientGroupPendingJoinAcceptConfirmationGroupMembershipStatusEnum,
	ClientGroupPendingJoinDenyConfirmationGroupMembershipStatusEnum,
} from 'digitale-doerfer-api/models';
import WarningView from '../shared/views/Warning.view';

enum ComponentState {
	INITIAL,
	QUERY_PARAMS_PARSED,
	LOADING,
	ERROR,
	SUCCESS_DENIED,
	SUCCESS_ALREADY_DENIED,
	SUCCESS_APPROVED,
	SUCCESS_ALREADY_APPROVED,
}

type RequestType = 'accept' | 'deny';

type RequestData = {
	type: RequestType;
	groupId: string;
	groupAdminId: string;
	applicantId: string;
	token: string;
};

function GroupJoinApproveDenyLandingPageContainer(): JSX.Element {
	const [pageState, setPageState] = useState<ComponentState>(
		ComponentState.INITIAL
	);
	const [requestData, setRequestData] = useState<RequestData | null>(null);
	const [errorCode, setErrorCode] = useState<string>('');

	const { search } = useLocation();

	if (pageState === ComponentState.INITIAL) {
		document.title = 'Gruppenmitgliedschaft';
		const { type, group, admin, person, token } = parse(search, {
			decode: true,
		});
		if (
			(type !== 'accept' && type !== 'deny') ||
			typeof group !== 'string' ||
			typeof admin !== 'string' ||
			typeof person !== 'string' ||
			typeof token !== 'string'
		) {
			setPageState(ComponentState.ERROR);
			setErrorCode('MISSING_OR_WRONG_QUERY_PARAM');
		} else {
			setPageState(ComponentState.QUERY_PARAMS_PARSED);
			setRequestData({
				type,
				groupAdminId: admin,
				groupId: group,
				applicantId: person,
				token,
			});
		}
	}

	async function verifyMail(requestData: RequestData): Promise<void> {
		try {
			if (requestData.type === 'accept') {
				const response = await groupEventApi().acceptPendingGroupJoinRequestMailUsingPOST(
					{
						mailToken: requestData.token,
						acceptingPersonId: requestData.groupAdminId,
						clientRequest: {
							groupId: requestData.groupId,
							personIdToAccept: requestData.applicantId,
						},
					}
				);
				if (response.groupMembershipStatusChanged) {
					// status changed to ACCEPT
					setPageState(ComponentState.SUCCESS_APPROVED);
				} else {
					// group membership was already accepted or denied previously
					setPageState(
						response.groupMembershipStatus ===
							ClientGroupPendingJoinAcceptConfirmationGroupMembershipStatusEnum.APPROVED
							? ComponentState.SUCCESS_ALREADY_APPROVED
							: ComponentState.SUCCESS_ALREADY_DENIED
					);
				}
			} else {
				// type = 'deny'
				const response = await groupEventApi().denyPendingGroupJoinRequestMailUsingPOST(
					{
						mailToken: requestData.token,
						denyingPersonId: requestData.groupAdminId,
						clientRequest: {
							groupId: requestData.groupId,
							personIdToDeny: requestData.applicantId,
						},
					}
				);
				if (response.groupMembershipStatusChanged) {
					// status changed to DENY
					setPageState(ComponentState.SUCCESS_DENIED);
				} else {
					// group membership was already accepted or denied previously
					setPageState(
						response.groupMembershipStatus ===
							ClientGroupPendingJoinDenyConfirmationGroupMembershipStatusEnum.APPROVED
							? ComponentState.SUCCESS_ALREADY_APPROVED
							: ComponentState.SUCCESS_ALREADY_DENIED
					);
				}
			}
		} catch (error) {
			// if the backend returns a non-ok code, the complete API Response
			// object is thrown as an error
			if (error instanceof Response) {
				const json = await error.json();
				setErrorCode(json.type);
				setPageState(ComponentState.ERROR);
			} else {
				setPageState(ComponentState.ERROR);
			}
		}
	}

	useEffect(() => {
		if (
			pageState === ComponentState.QUERY_PARAMS_PARSED &&
			requestData !== null
		) {
			setPageState(ComponentState.LOADING);
			verifyMail(requestData);
		}
	}, [pageState, requestData]);

	return (
		<MainView>
			{pageState === ComponentState.LOADING && <LoadingView />}
			{pageState === ComponentState.SUCCESS_APPROVED && (
				<SuccessView message="Gruppenmitgliedschaft bestätigt!" />
			)}
			{pageState === ComponentState.SUCCESS_DENIED && (
				<SuccessView message="Gruppenmitgliedschaft abgelehnt!" />
			)}
			{pageState === ComponentState.SUCCESS_ALREADY_APPROVED && (
				<WarningView message="Die Gruppenmitgliedschaft wurde bereits bestätigt!" />
			)}
			{pageState === ComponentState.SUCCESS_ALREADY_DENIED && (
				<WarningView message="Die Gruppenmitgliedschaft wurde bereits abgelehnt!" />
			)}
			{pageState === ComponentState.ERROR && (
				<ErrorView
					message="Ups, etwas ist schiefgelaufen"
					backendErrorCode={errorCode}
				/>
			)}
		</MainView>
	);
}

export default GroupJoinApproveDenyLandingPageContainer;
